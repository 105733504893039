<template>
<footer id="contact">
    <div class="footer-info">
        <div class="footer-info-desc">
            <div class="footer-info-header">
                <h3>SPERTOO</h3>
                <span class="footer-line"></span>
            </div>
            <p v-for="(translation, key) in translations" :key="key">{{ $t(translation) }}</p>
        </div>
        <div class="footer-info-contact">
            <div class="footer-info-header">
                <h3>{{ $t('contact') }}</h3>
                <span class="footer-line"></span>
            </div>
            <div class="footer-info-contact-method">
                <p>Tel: </p>
                <a href="tel:+34934751739">+34 93 475 17 39</a>
            </div>
            <div class="footer-info-contact-method">
                <p>E-mail: </p>
                <a href="mailto: info@spertoo.com">info@spertoo.com</a>
            </div>
        </div>
        <div class="footer-info-logo">
            <a href="#">
                <img :src="getImageForLocale" alt="Spertoo">
            </a>
        </div>
    </div>
    <div class="footer-legals">
        <p>© Spertoo | <router-link to="/legal">{{ $t('footer_legal_warning') }}</router-link > | <router-link to="/cookies">{{ $t('footer_cookies_policy') }}</router-link> | <router-link to="/data">{{ $t('footer_data_policy') }}</router-link></p>
    </div>
</footer>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'FooterSection',
    data() {
        return {
            translations: ["footer_spertoo_desc1", "footer_spertoo_desc2", "footer_spertoo_desc3", "footer_spertoo_desc4", "footer_spertoo_desc5"]
        }
    },
    setup() {
        const locale = useI18n();

        const getImageForLocale = computed(() => {
            if (locale.locale.value == 'es') {
                return require(`@/assets/img/spertoo-logo-base-es.png`);
            } else if (locale.locale.value == 'ca') {
                return require(`@/assets/img/spertoo-logo-base-ca.png`);
            } else {
                return require(`@/assets/img/spertoo-logo-base-en.png`);
            }
        });

        return {
            getImageForLocale
        };
    }
})
</script>

<style scoped>
footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: #1a1a1a;
}

p,
a,
h3 {
    color: #FFFFFF;
    margin: 0;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}

h3 {
    text-transform: uppercase;
}

p,
a {
    font-family: Raleway;
    font-size: 15px;
    font-style: 100;
    font-weight: 100;
    line-height: 1.3;
}

.footer-line {
    display: block;
    border: 1px solid #FFFFFF;
    height: 1px;
    width: 100%;
    margin: auto 0 auto 20px;
}

.footer-info {
    max-width: 1200px;
    width: 100%;
    min-height: 230px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}

.footer-info-desc,
.footer-info-contact,
.footer-info-logo {
    min-width: 330px;
    padding: 50px 20px;
}

.footer-info-logo {
    height: 160px;
    width: auto;
    padding: 20px 10px;
    margin: auto 0;
}

.footer-info-logo>a>img {
    height: 100%;
    width: auto;
    object-fit: cover;
}

.footer-info-header {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
}

.footer-info-contact-method {
    display: flex;
    justify-content: space-between;
}

.footer-info-contact-method>a {
    text-decoration: none;
    color: #00A3FF;
    font: normal normal normal 15px Raleway;
}

.footer-legals {
    width: 100%;
    height: 50px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 17px Raleway;
}

.footer-legals>p>a:link {
    text-decoration: none;
}

.footer-legals>p>a:visited {
    text-decoration: none;
}

.footer-legals>p>a:hover {
    text-decoration: none;
}

.footer-legals>p>a:active {
    text-decoration: none;
}

@media only screen and (max-width: 660px) {

    .footer-info-desc,
    .footer-info-contact,
    .footer-info-logo {
        min-width: 0;
        width: 80%;
        margin: auto 10px;
        padding: 20px 20px;
    }

    .footer-info-logo {
        height: 110px;
        padding: 20px 0;
    }
}
</style>
