<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
}
}
</script>

<style>
@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-Bold.ttf);
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-ExtraBold.ttf);
  font-weight: 800;
  font-style: 800;
}

@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-Black.ttf);
  font-weight: 900;
  font-style: 900;
}
/* 
@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-Light.ttf);
  font-weight: lighter;
  font-style: lighter;
} */

@font-face {
  font-family: "Raleway";
  src:url(./assets/fonts/Raleway-Thin.ttf);
  font-weight: 100;
  font-style: 100;
} 

#app {
  font-family: "Raleway";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
