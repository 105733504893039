<template>
    <TestTest />
    <SliderHeader />
    <MainSection />
    <AboutSection />
    <FooterSection />
    <CookieLaw />
</template>
    
    <script>
    import SliderHeader from './SliderHeader.vue';
    import AboutSection from './AboutSection.vue';
    import MainSection from './MainSection.vue';
    import TestTest from './TestTest.vue';
    import FooterSection from './FooterSection.vue';
    import CookieLaw from './CookieLaw.vue';

    export default {
        name: "HomeSection",
        components: {
            // HeaderNavbar,
            SliderHeader,
            AboutSection,
            MainSection,
            FooterSection,
            TestTest,
            CookieLaw
        },
        data() {
            return {
                translations1: ["mainsection_erp_desc1","mainsection_erp_desc2","mainsection_erp_desc3","mainsection_erp_desc4","mainsection_erp_desc5","mainsection_erp_desc6","mainsection_erp_desc7"],
                translations2: ["mainsection_desarrollo_a_medida_desc1", "mainsection_desarrollo_a_medida_desc2", "mainsection_desarrollo_a_medida_desc3"],
                translations3: ["mainsection_consultoria_desc1", "mainsection_consultoria_desc2", "mainsection_consultoria_desc3"]
            };
        }
    };
    </script>
    
    <style scoped>
    main {
        padding: 60px 0;
    }
    
    h1, h3 {
        text-transform: uppercase;
        font: normal normal bold 35px Raleway;
        font-weight: 900;
        color: #333333;
    }
    p {
        font: normal normal normal 14px Raleway;
        color: #8F8E8E;
    }
    .title-container {
        padding: 0 20px;
    }
    .blue-text {
        color: #00A3FF;
    }
    .bold-text {
        font-weight: 800;
    }
    .cards-container {
        display: flex;
        justify-content: center;
        margin: 100px 0 20px 0;
    }
    .cards-container>.card {
        border: 0px solid;
        width: 280px;
        align-items: center;
        margin: 0 20px;
    }
    
    .cards-container>.card>img {
        height: 108px;
        width: 108px;
    }
    
    .cards-container>.card>h3 {
        margin: 35px 0 20px 0;
        font-size: 20px;
    }
    
    .cards-container>.card>.card-desc>p {
        margin: 0;
        padding: 1px;
    }
    
    @media only screen and (max-width: 980px) {
        main{
            padding: 30px 0;
        }
        .cards-container {
            flex-direction: column;
            align-items: center;
            margin: 25px 0 ;
        }
        .cards-container>.card{
            margin: 35px 0;
        }
    }
    </style>
    