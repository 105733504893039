<template>
        <div class="aviso" v-if="notcookies">
          <p v-html="$t('landing_cookies_message1')" />

          <div class="buttomContainer">
            <br/>
            <a @click="accept" class="button-accept" href="#">{{ $t('landing_cookies_accept') }}</a>
            <a @click="view" class="button-view" href="#">{{ $t('landing_cookies_more_info') }}</a>
          </div>
        </div>
        <div v-if="visible" class="dialog">
            <div class="legal-container">
                <div>
                    <h1>{{ $t('landing_cookies_title1') }}</h1>
                    <h2>{{ $t('landing_cookies_title2') }}</h2>
                    <p>
                        {{ $t('landing_cookies_paragraph1') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph2') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph3') }}
                        <br><br>
                    </p>
                    <h2>{{ $t('landing_cookies_title3') }}</h2>
                    <p>
                        {{ $t('landing_cookies_paragraph1') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph5') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph6') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph7') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph8') }}
                        <br><br>
                    </p>          
                    <h2>{{ $t('landing_cookies_title4') }}</h2>
                    <p>
                        {{ $t('landing_cookies_paragraph9') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph10') }}
                        <br><br>
                        {{ $t('landing_cookies_paragraph11') }}
                        <br><br>
        
                        <a href="https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">Chrome</a>
                        <br><br>
                        <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">Explorer</a>
                        <br><br>
                        <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Firefox</a>
                        <br><br>
                        <a href="https://support.apple.com/kb/ph5042">Safari</a>
                        <br><br>
        
        
                        {{ $t('landing_cookies_paragraph12') }}
                        <br><br>
                    </p>          
                  
                </div>
            </div>
            <div>
                <a @click="close" class="button-close">{{ $t('landing_cookies_close') }}</a>
            </div>

        </div>
</template>

<script>
    import VueCookies from 'vue-cookies'
    
    export default {
        data() {
            return {
               visible : false,
               notcookies: false
            };
        },
        name: 'CookieLaw',
        components: {
            // HeaderNavbar,
        },
        methods: {
            accept() {
                VueCookies.set('cookies' , "accept") 
                this.$data.notcookies = false;
            },
            view() {
                this.$data.visible = true;
            },
            close() {
                this.$data.visible = false;
            }
        },
        created: function () {
            let accept = VueCookies.get("cookies") != null && VueCookies.get("cookies") == 'accept';
            this.$data.notcookies = !accept;
        }
    }
</script>

<style scoped>
.legal-container {
    margin: 5px;
    overflow: auto;
    max-height: 55vh;
    padding: 10px;
    margin-bottom: 20px;
}
.dialog {
    position: absolute;
    top: 15vh;
    left: 25vw;
    border-radius: 10px;
    background-color: rgb(250, 246, 246);
    width: 50vw;
    min-height: 65vh;

}
.button-accept {
    margin-top: 10px;
    padding: 10px;
    background-color: rgb(86, 148, 117);
    color: white;
    border-radius: 10px;
}
.button-view {
    padding: 10px;
    margin-left: 10px;
    background-color: rgb(101, 103, 107);
    color: white;
    border-radius: 10px;
}
.button-close {
    padding: 10px;
    background-color: rgb(187, 188, 190);
    color: rgb(9, 9, 9);
    border-radius: 10px;
    cursor: pointer;

}
.buttomContainer {
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;
}
section {
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
}
.aviso {
    background-color: rgb(234, 233, 233);
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 5px;
    justify-items: center;
    justify-self: center;
    justify-content: center;
}
h1 {
    text-align: start;
}
h2 {
    text-align: start;
}
p {
    padding: 20px;
    text-align: start;
}
section>div {
    margin: 50px auto;
    font: normal normal normal 17px Raleway;
    text-align: center;
    max-width: 1000px;
    padding: 10px 25px;
}

section>div>p {
    margin: 25px auto;
    color: #8F8E8E;
}

section>div>p>span {
    font: normal normal bold 20px Raleway;
    color: #333333;
}

h1 {
    text-transform: uppercase;
    font: normal normal bold 35px Raleway;
    font-weight: 900;
    color: #333333;
}

.partner-section {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 45px;
}

.partner-section>p {
    margin: 0 8px 5px 8px;
    font-size: 17px;
    text-transform: uppercase;
}

.partner-section>img {
    margin: 0;
    height: 50px;
}

img.img-deister {
    height: 34px;
    margin-bottom: 1px;
}

@media only screen and (max-width: 800px) {
    .partner-section>p {
        font-size: 16px;
    }

    .partner-section>img {
        height: 45px;
    }

    img.img-deister {
        height: 32px;
    }
}

@media only screen and (max-width: 600px) {

    p,
    h1 {
        padding: 0 15px;
    }

    .partner-section>p {
        font-size: 14px;
        padding: 0;
    }

    .partner-section>img {
        height: 37px;
    }

    img.img-deister {
        height: 29px;
    }

}

@media only screen and (max-width: 500px) {
    .partner-section>p {
        font-size: 12px;
        margin: 4px;
    }

    .partner-section>img {
        height: 27px;
    }

    img.img-deister {
        height: 23px;
    }
}

@media only screen and (max-width: 400px) {
    .partner-section>img {
        height: 23px;
    }

    img.img-deister {
        height: 18px;
    }

    .partner-section>p {
        margin-bottom: 2px;
    }
}

@media only screen and (max-width: 325px) {
    .partner-section>img {
        height: 21px;
    }

    img.img-deister {
        height: 15px;
    }

    .partner-section>p {
        font-size: 11px;
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 380px) {
    section>div {
        padding: 10px 0;
    }

}
</style>

