import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/HomeSection.vue'
import Legal from './components/LegalSection.vue'
import ProtectionDataSection from './components/ProtectionDataSection.vue'
import CookiesSetion from './components/CookiesSection.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/legal', component: Legal },
        { path: '/', component: Home },
        { path: '/data', component: ProtectionDataSection },
        { path: '/cookies', component: CookiesSetion },
    ]
});

// BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

// Language Files
import es from './locales/es.json'
import en from './locales/en.json'
import ca from './locales/ca.json'


const i18n = createI18n({
    legacy: false,
    locale: 'es',
    messages: {
        es,
        en,
        ca
    }
})

const app = createApp(App)
app.use(i18n)
app.use(router);
app.mount('#app')
