<template>
<div class="carousel slide slider" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div v-for="(slide, index) in slides" :key="index" class="carousel-item" :class="{ active: index === activeSlide }">
            <img :src="slide.image" class="slider-image" alt="Slide">
            <div class="text-overlay">
                <p>{{ $t(slide.text) }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'SliderHeader',
    data() {
        return {
            activeSlide: 0,
            slides: [{
                    "image": require("@/assets/img/imagen-slide-1.jpg"),
                    "text": "slider_text_photo1"
                },
                {
                    "image": require("@/assets/img/imagen-slide-2.jpg"),
                    "text": "slider_text_photo2"
                },
                {
                    "image": require("@/assets/img/imagen-slide-3.jpg"),
                    "text": "slider_text_photo3"
                }
            ]
        };
    },
    created() {
        this.startCarousel();
    },
    methods: {
        startCarousel() {
            setInterval(() => {
                this.activeSlide = (this.activeSlide + 1) % this.slides.length;
            }, 4500); // Cambiar cada 4.5 segundos
        }
    }
}
</script>

<style scoped>
.slider {
    width: 100%;
    height: 420px;
    position: relative;
    display: inline-block;
}

.slider-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 40px 20px;
    font-size: 16px;
    text-align: left;
    min-width: 225px;
}
.carousel-inner {
    height: 100%;
}
.carousel-item {
    height: 100%;
}

.text-overlay p {
    margin: 0;
    /* width: 225px; */
    min-width: 225px;
    max-width: 415px;
    text-transform: uppercase;
    text-align: left;
    font: normal normal normal 17px Raleway;
}

@media only screen and (max-width: 1200px) {
    .slider {
        height: 380px;
    }
}

@media only screen and (max-width: 1000px) {
    .slider {
        height: 340px;
    }
}

@media only screen and (max-width:800px) {
    .slider {
        height: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .slider {
        height: 260px;
    }
    .text-overlay {
        width: auto;
    }
    .text-overlay p {
        width: 225px;
    }
}

/* @media only screen and (max-width: 400px) {
    .slider {
        height: 270px;
    }
} */
</style>
