<template>
<section id="about-us">
    <div>
        <h1>{{ $t('aboutUs') }}</h1>
        <p>
            <span>Spertoo</span>
            {{ $t('landingpage_AboutUs_paragraph1') }}
            <br><br>
            {{ $t('landingpage_AboutUs_paragraph2') }}
            <br><br>
            {{ $t('landingpage_AboutUs_paragraph3') }}
            <br><br>
            {{ $t('landingpage_AboutUs_paragraph4') }}
            <br><br><br>
            <span>{{ $t('landingpage_AboutUs_paragraph5_span') }}</span>
            <br><br><br>
            {{ $t('landingpage_AboutUs_paragraph6') }}
        </p>
        <div class="partner-section">
            <img src="@/assets/img/spertoo-logo-gris-oscuro.png" alt="Spertoo">
            <p>{{ $t('landingpage_AboutUs_partner') }}</p>
            <img src="@/assets/img/logo-deister-software.png" class="img-deister" alt="DEISTER SOFTWARE">
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'AboutSection',
}
</script>

<style scoped>
section {
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
}

section>div {
    margin: 50px auto;
    font: normal normal normal 17px Raleway;
    text-align: center;
    max-width: 1000px;
    padding: 10px 25px;
}

section>div>p {
    margin: 25px auto;
    color: #8F8E8E;
}

section>div>p>span {
    font: normal normal bold 20px Raleway;
    color: #333333;
}

h1 {
    text-transform: uppercase;
    font: normal normal bold 35px Raleway;
    font-weight: 900;
    color: #333333;
}

.partner-section {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 45px;
}

.partner-section>p {
    margin: 0 8px 5px 8px;
    font-size: 17px;
    text-transform: uppercase;
}

.partner-section>img {
    margin: 0;
    height: 50px;
}

img.img-deister {
    height: 34px;
    margin-bottom: 1px;
}

@media only screen and (max-width: 800px) {
    .partner-section>p {
        font-size: 16px;
    }

    .partner-section>img {
        height: 45px;
    }

    img.img-deister {
        height: 32px;
    }
}

@media only screen and (max-width: 600px) {

    p,
    h1 {
        padding: 0 15px;
    }

    .partner-section>p {
        font-size: 14px;
        padding: 0;
    }

    .partner-section>img {
        height: 37px;
    }

    img.img-deister {
        height: 29px;
    }

}

@media only screen and (max-width: 500px) {
    .partner-section>p {
        font-size: 12px;
        margin: 4px;
    }

    .partner-section>img {
        height: 27px;
    }

    img.img-deister {
        height: 23px;
    }
}

@media only screen and (max-width: 400px) {
    .partner-section>img {
        height: 23px;
    }

    img.img-deister {
        height: 18px;
    }

    .partner-section>p {
        margin-bottom: 2px;
    }
}

@media only screen and (max-width: 325px) {
    .partner-section>img {
        height: 21px;
    }

    img.img-deister {
        height: 15px;
    }

    .partner-section>p {
        font-size: 11px;
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 380px) {
    section>div {
        padding: 10px 0;
    }

}
</style>
