<template>
<header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img src="@/assets/img/spertoo-logo.png" alt="Logo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link first-nav-link" href="/">{{ $t('home') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  href="/#about-us" v-scroll-to="'#about-us'" >{{ $t('aboutUs') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#contact">{{ $t('contact') }}</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ $t('language') }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                            <li><a @click="changeLocale('ca')" class="dropdown-item"><img src="@/assets/img/ca.png" alt="catalan"> CA</a></li>
                            <li><a @click="changeLocale('es')" class="dropdown-item"><img src="@/assets/img/es.png" alt="castellano"> ES</a></li>
                            <li><a @click="changeLocale('en')" class="dropdown-item"><img src="@/assets/img/en.png" alt="ingles"> EN</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
</template>

<script>
export default {
    name: "TestTest",
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
        },
        scrollToSite(event) {
            // Evita que el enlace siga la acción predeterminada de cambiar la URL
            event.preventDefault();

            // Obtiene el elemento objetivo usando el atributo "href"
            const targetElement = document.querySelector(event.target.getAttribute('href'));

            // Verifica si el elemento objetivo existe
            if (targetElement) {
                // Define la distancia personalizada que deseas desplazar
                const customScrollDistance = 70; // Cambia este valor según tus necesidades

                // Calcula la posición final a la que te deseas desplazar
                const finalScrollPosition = targetElement.getBoundingClientRect().top + window.scrollY - customScrollDistance;

                // Realiza el desplazamiento utilizando window.scrollTo()
                window.scrollTo({
                    top: finalScrollPosition,
                    behavior: 'smooth', // Opcional: para un desplazamiento suave y animado
                });
            }
        }
    },
}
</script>

<style scoped>
header {
    position: sticky;
    z-index: 10000;
    width: 100%;
    top: 0;
}

.container-fluid {
    padding: 0 30px;
    max-width: 1700px;
}

.nav-link {
    min-width: 100px;
    height: 20px;
    color: #ffffff;
    margin: 0 10px;
    font: normal normal bold 16px/19px Raleway;
    text-align: center;
    text-transform: uppercase;
}

.navbar-nav {
    --bs-nav-link-padding-y: 0;
}

.first-nav-link {
    color: #00A3FF !important;
}

.navbar-toggler {
    border: 0 solid #FFFFFF;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.dropdown-menu {
    --bs-dropdown-min-width: 40px
}

.dropdown-menu.show {
    display: block;
    width: 85px;
    align-items: center;
    margin: 0 auto;
}

.nav-link:hover,
.nav-link:focus,
.navbar-toggler:focus {
    color: #ffffff;
    box-shadow: none;
}

@media only screen and (max-width: 450px) {
    .container-fluid {
        padding: 0 15px;
    }
}
</style>
